<template>
  <div class="dlocal-wrapper" ref="dlocal" id="dlocal" v-loading.fullscreen="fullscreenLoading">
    <div class="content-wrapper" v-if="paymentInfo">
      <div class="header-wrapper">
        <div class="merchant-logo logo">
          <img v-if="paymentInfo.brandConfig && paymentInfo.brandConfig.base64Logo"
            :src="`data:image/png;base64,${paymentInfo.brandConfig.base64Logo}`" style="height: 100%;" />
        </div>
        <div class="ticket-logo logo">
          <img v-if="paymentInfo.checkoutModelResp && paymentInfo.checkoutModelResp.base64Logo"
            :src="`data:image/png;base64,${paymentInfo.checkoutModelResp.base64Logo}`" style="height: 100%;" />
        </div>
      </div>
      <div class="order-wrapper pc">
        <div class="amount-info item">
          <div class="name">{{ $t('message.dlocal.amount') }}:</div>
          <div class="value">{{ paymentInfo.txnCurrency }} {{ paymentInfo.presentContext.amount ||
            paymentInfo.txnAmount }}</div>
        </div>
        <div class="expiration-info item">
          <div class="name">{{ $t('message.dlocal.expirationDate') }}:</div>
          <div class="value">{{ paymentInfo.presentContext.expiration_date || paymentInfo.presentContext.expiredDate }}
          </div>
        </div>
      </div>
      <div class="order-wrapper mobile">
        <div class="amount-info item margin-bottom-6">
          <div class="name">{{ $t('message.dlocal.amount') }}:</div>
          <div class="value">{{ paymentInfo.txnCurrency }} {{ paymentInfo.presentContext.amount || paymentInfo.txnAmount
            }}</div>
        </div>
        <div class="expiration-info item">
          <div class="name">{{ $t('message.dlocal.expirationDate') }}:</div>
          <div class="value">{{ paymentInfo.presentContext.expiration_date || paymentInfo.presentContext.expiredDate }}
          </div>
        </div>
      </div>
      <div class="ticket-wrapper">
        <div class="method" v-if="paymentInfo.presentContext.number || paymentInfo.presentContext.paymentCode">
          <span class="name">{{ paymentInfo.checkoutModelResp.cardName }}</span>
          <!-- <el-button class="button pc" type="text" @click="copyTicket" v-if="paymentInfo.presentContext.number">
            <span class="iconfont icon-rongqi"></span>
          </el-button> -->
        </div>
        <div class="ticket-item" v-if="paymentInfo.presentContext.number || paymentInfo.presentContext.paymentCode">
          <div id="ticketNumber" class="ticket">{{ paymentInfo.presentContext.number ||
            paymentInfo.presentContext.paymentCode }}</div>
          <el-button class="button" type="text" @click="copyTicket">
            <span class="iconfont icon-rongqi"></span>
          </el-button>
        </div>
        <div v-if="paymentInfo.qrCode">
          <div class="img-title">{{ $t('message.dlocal.ticketImage') }}:</div>
          <div class="img">
            <el-image v-if="paymentInfo.qrCode.indexOf('data:image/png;base64,') > -1" :src="paymentInfo.qrCode"
              style="height: 260px;"></el-image>
            <el-image v-else :src="`data:image/png;base64,${paymentInfo.qrCode}`"
              style="height: 58px;width: 100%;padding:10px 0;"></el-image>
          </div>
        </div>
        <div v-if="['SPEI'].includes(paymentInfo.checkoutModelResp.cardName)" class="combination-wrapper">
          <div class="item">
            <div class="title bank-title" v-html="$t('message.dlocal.bankDetailsTitle')"></div>
            <div class="content" v-html="$t(`message.dlocal.bankDetails${paymentInfo.checkoutModelResp.cardName}`, {
              bank: paymentInfo.presentContext.bank_name,
              clabe: paymentInfo.presentContext.bank_account_label,
              bankAccount2: paymentInfo.presentContext.bank_account,
              beneficiary: paymentInfo.presentContext.beneficiary_name,
              amount: paymentInfo.txnCurrency,
              currency: paymentInfo.presentContext.amount,
              concept: paymentInfo.presentContext.reference,
              reference: paymentInfo.presentContext.reference,
            })"></div>
          </div>
        </div>
        <div v-if="['Servipag'].includes(paymentInfo.checkoutModelResp.cardName)" class="combination-wrapper">
          <div class="item">
            <div class="content">
              <p class="bank-desc" style="text-align:center;margin-top:20px">Primero díctale al cajero el código de
                servicio:</p>
              <div class="bank-info-box servipag">
                <div class="servipag-item">
                  <div class="number">{{ paymentInfo.presentContext.company_id }}</div>
                  <div class="number-desc">Si es YoPag</div>
                </div>
                <div class="servipag-item">
                  <div class="number">{{ paymentInfo.presentContext.company_id2 }}</div>
                  <div class="number-desc">Si es por caja</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="btns">
          <el-button @click="download" class="button" type="primary">{{ $t('message.dlocal.downloadTicket')
            }}</el-button>
          <el-link v-if="shop[paymentInfo.checkoutModelResp.cardName]" class="button search"
            :href="shop[paymentInfo.checkoutModelResp.cardName]" target="_blank" type="primary" :underline="false">
            {{ $t('message.dlocal.searchForShops') }}
          </el-link>
        </div>
      </div>
      <div
        v-if="['PIX', 'Redpagos', 'OXXO', 'SPEI', 'Sencillito', 'Servipag', 'Boleto'].includes(paymentInfo.checkoutModelResp.cardName)"
        class="combination-wrapper">
        <div v-if="['PIX', 'Redpagos', 'OXXO', 'SPEI', 'Sencillito'].includes(paymentInfo.checkoutModelResp.cardName)"
          class="item">
          <div class="title" v-html="$t('message.dlocal.instructionsTitle')"></div>
          <div class="content"
            v-html="$t(`message.dlocal.instructionsContent${paymentInfo.checkoutModelResp.cardName}`, { number: paymentInfo.presentContext.number })">
          </div>
        </div>
        <div v-if="['Servipag'].includes(paymentInfo.checkoutModelResp.cardName)" class="item">
          <div class="title" v-html="$t('message.dlocal.paymentTypeTitle')"></div>
          <div class="content">
            <span
              v-html="$t(`message.dlocal.paymentType${paymentInfo.checkoutModelResp.cardName}`, { number: paymentInfo.presentContext.number })"></span>
          </div>
        </div>
        <!-- <div class="btns" v-show="['Boleto', 'Sencillito', 'Redpagos', 'OXXO', 'Servipag'].includes(paymentInfo.checkoutModelResp.cardName)">
          <el-button @click="onFullView" type="primary" class="button">{{ $t('message.dlocal.fullBoletoView') }}</el-button>
        </div> -->
      </div>
      <!-- <div class="back-wrapper" v-if="paymentInfo.returnUrl">
        <el-link :href="paymentInfo.returnUrl" target="_blank" type="primary" :underline="false">
          <span class="iconfont icon-fanhui"></span>{{ $t('message.dlocal.back') }}
        </el-link>
      </div> -->
    </div>
    <div v-if="showErrorMsg" class="error-msg">
      <img class="unknow" src="../../assets/images/unknow.png" />
      <div class="tips">{{ $t('message.dlocal.errorMsg') }}</div>
    </div>
  </div>
</template>
<script>
import FileSaver from 'file-saver';
import { toJpeg, toBlob } from 'html-to-image';

import { getLocalPaymentInfo } from '@/api/checkout';
import { loadLanguageAsync } from '@/language/index';
import { copyString } from '@/utils/tools/utils';

export default {
  data() {
    return {
      fullscreenLoading: true,
      txnId: '',
      paymentInfo: null,
      showErrorMsg: false,
      shop: {
        Redpagos: ' https://www.google.com/maps/search/redpagos/',
        OXXO: 'https://google.com/maps/search/oxxo/',
        Servipag: 'https://google.com/maps/search/servipag/',
        Sencillito: 'https://www.google.com/maps/search/sencillito/',
      },
      lang: {
        PIX: 'pt',
        Redpagos: 'es',
        OXXO: 'es',
        SPEI: 'es',
        Servipag: 'es',
        Boleto: 'pt',
        Sencillito: 'es',
      },
    };
  },
  created() {
    this.txnId = this.$route.query.txnId;
    getLocalPaymentInfo(this.txnId).then((res) => {
      this.fullscreenLoading = false;
      if (res.respCode === '20000') {
        this.paymentInfo = res.data;
        if (res.data.checkoutModelResp && this.lang[res.data.checkoutModelResp.cardName]) {
          loadLanguageAsync(this.lang[res.data.checkoutModelResp.cardName]);
        }
        if (res.data.brandConfig) {
          this.$store.dispatch('app/setBrandInfo', res.data.brandConfig);
        }
        if (this.paymentInfo.paymentMethod) {
          const { paymentMethod } = this.paymentInfo;
          const _paymentMethod = paymentMethod.toUpperCase();
          const payType = _paymentMethod.split('_')[0];
          if (payType === 'QR') {
            this.$router.push({ path: '/payment/local/qrcode', query: { txnId: this.txnId, type: '2' } });
          } else if (payType === 'DANA') {
            this.$router.push({ path: '/payment/local/dana', query: { txnId: this.txnId, type: '2' } });
          }
        }

        if (!res.data) {
          this.showErrorMsg = true;
        }
      } else {
        this.showErrorMsg = true;
      }
    }).catch(() => {
      this.showErrorMsg = true;
      this.fullscreenLoading = false;
    });
  },
  methods: {
    copyTicket() {
      copyString(this.paymentInfo.presentContext.number ||
        this.paymentInfo.presentContext.paymentCode, this.$t('message.dlocal.copySuccess'));
    },
    setTicketNumberScroller(scroll) {
      try {
        const el = document.getElementById('ticketNumber');
        if (el && el.style) {
          el.style.overflowX = scroll ? 'scroll' : 'hidden';
        }
      } catch (err) {
        //
      }
    },
    download() {
      this.setTicketNumberScroller(false);
      toBlob(this.$refs.dlocal)
        .then(() => {
          // Fix Safari 会显示图片失败，使用再次绘制处理
          toBlob(this.$refs.dlocal)
            .then((blob) => {
              this.setTicketNumberScroller(true);
              if (window.saveAs) {
                window.saveAs(blob, 'payment_code.jpg');
              } else {
                FileSaver.saveAs(blob, 'payment_code.jpg');
              }
            }).catch((error) => {
              console.log('download page error', error);
              this.setTicketNumberScroller(true);
            });
        }).catch((error) => {
          console.log('download page error', error);
          this.setTicketNumberScroller(true);
        });
    },
    search() { },
    onFullView() {
      window.open(this.paymentInfo.presentContext.image_url, '_blank');
    },
  },
};
</script>
<style lang="scss" scoped>
.dlocal-wrapper {
  color: #27272B;
  min-height: calc(100vh - 80px);
  background-color: #FFF;

  .content-wrapper {
    padding-top: 70px;
    width: 1000px;
    margin: 0 auto;

    .header-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #FFF;
      border-radius: 15px;

      .logo {
        height: 60px;
      }
    }

    .order-wrapper {
      background-color: #EEEEEE;
      display: flex;
      align-items: center;
      padding: 38px 0;
      margin-top: 20px;

      &.mobile {
        display: none;
      }

      &.pc {
        display: flex;
      }

      .name {
        font-weight: 900;
        font-size: 18px;
        line-height: 25px;
      }

      .value {
        font-weight: 900;
        font-size: 28px;
        line-height: 38px;
      }

      .item {
        width: 50%;
        text-align: center;
      }
    }

    .ticket-wrapper {
      padding: 40px 170px 0;
      text-align: center;
      background: #FAFAFA;

      .combination-wrapper {
        padding: 0;
      }

      .method {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 900;
        font-size: 20px;

        .name {
          // margin-right: 20px;
        }

        .button {
          height: 27px;
          padding: 0;
        }

        ::v-deep(.icon-rongqi) {
          font-size: 20px;
          color: var(--color-primary);
        }
      }

      .ticket-item {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        gap: 10px;

        .button {
          // display: none;
          padding: 0;

          .icon-rongqi {
            font-size: 20px;
            color: var(--color-primary);
          }
        }
      }

      .ticket {
        border-radius: 2px;
        border: 1px solid #616D7A;
        padding: 0 12px;
        white-space: nowrap;
        overflow-x: scroll;
        text-overflow: ellipsis;
        max-width: 100%;
        font-weight: 500;
        font-size: 20px;
        color: #27272B;
        line-height: 27px;
        height: 43px;
        display: flex;
        align-items: center;
        margin-left: 28px;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .img-title {
        font-weight: 900;
        font-size: 20px;
        color: #27272B;
        line-height: 27px;
        margin-top: 40px;
        text-align: center;
      }

      .img {
        // border-radius: 2px 2px 2px 2px;
        // padding: 10px;
        margin-top: 10px;
        text-align: center;

        .el-image {
          border: 1px solid #616D7A;
        }
      }

      .btns {
        margin-top: 30px;
        display: flex;
        align-items: center;

        .button {
          width: 100%;
          margin: 0;
          border-radius: 2px;

          &.search {
            background-color: #FFF;
            // margin-top: 15px;
            margin-left: 20px;
            border: 1px solid var(--color-primary);
            color: var(--color-primary);
            text-align: center;
            display: block;
            height: 44px;
            line-height: 44px;
            padding: 0 20px;
            font-size: 14px;
            font-weight: 800;
            box-sizing: border-box;

            ::v-deep(.el-link--inner) {
              display: flex;
              align-items: center;
              justify-content: center;

              .el-icon-search {
                margin-right: 5px;
              }
            }
          }
        }
      }
    }

    .combination-wrapper {
      text-align: left;
      background: #FAFAFA;
      padding: 20px 170px;

      .item {
        margin-top: 30px;

        &:first-child {
          margin-top: 0px;
        }

        .title {
          font-weight: 900;
          font-size: 20px;
          color: #27272B;
          line-height: 27px;

          &.bank-title {
            text-align: center;
            margin-bottom: 20px;
          }
        }

        .content {
          font-weight: 500;
          font-size: 16px;
          color: #616D7A;
          line-height: 22px;
          margin-top: 10px;
        }

        ::v-deep(.bank-desc) {
          font-weight: 500;
          font-size: 16px;
          color: #616D7A;
          line-height: 22px;
          margin: 0;
          margin-bottom: 20px;
        }

        ::v-deep(.bank-info-item) {
          display: flex;
          justify-content: space-between;
          margin: 0;
          line-height: 24px;
          font-weight: 500;
          font-size: 16px;
          color: #616D7A;
          padding: 4px 0;
          flex-wrap: wrap;
        }

        ::v-deep(.bank-info-box) {
          margin: 0;
          background: #FFFFFF;
          border-radius: 2px 2px 2px 2px;
          border: 1px solid #616D7A;
          padding: 20px;

          &.servipag {
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding-top: 44px;
            padding-bottom: 38px;

            .servipag-item {
              width: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;

              .number {
                background: #EEEEEE;
                border-radius: 2px;
                color: var(--color-primary);
                padding: 7px 30px;
                font-weight: 500;
                font-size: 16px;
                line-height: 32px;
                min-height: 46px;
                min-width: 113px;
                box-sizing: border-box;
              }

              .number-desc {
                font-weight: 500;
                font-size: 16px;
                color: #616D7A;
                line-height: 32px;
                margin-top: 10px;
              }
            }
          }
        }

        ::v-deep(.detail-title) {
          flex-shrink: 0;
        }

        ::v-deep(.detail-value) {
          color: #27272B;
        }

        ::v-deep(.company) {
          color: var(--color-primary);
        }
      }

      .btns {
        .button {
          width: 100%;
          margin-top: 10px;
          border-radius: 2px;
        }
      }
    }

    .back-wrapper {
      padding: 20px 0 60px;

      .el-link--primary {
        display: block;
        width: 100%;
        font-weight: 500;
        font-size: 14px;
        color: var(--color-primary);
        text-align: center;

        .icon-fanhui {
          margin-right: 7px;
        }
      }
    }
  }

  .error-msg {
    padding: 200px 15px 0;
    width: 690px;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0 auto;

    .unknow {
      width: 134px;
      height: auto;
    }

    .tips {
      font-weight: 500;
      font-size: 18px;
      color: #616D7A;
      line-height: 25px;
      text-align: left;
      margin-top: 40px;
      text-align: center;
    }
  }
}

@media screen and(max-width: 1000px) {
  .dlocal-wrapper {
    .content-wrapper {
      width: 100%;
      padding: 10px;
      box-sizing: border-box;

      .header-wrapper {
        .logo {
          height: 30px;
        }
      }

      .order-wrapper {
        padding: 14.5px 0;

        &.mobile {
          display: flex;
          flex-direction: column;
          padding-left: 10px;
          padding-right: 10px;

          .item {
            display: flex;
            justify-content: space-between;
            width: 100%;
            // padding: 0 10px;
          }
        }

        &.pc {
          display: none;
        }

        .name {
          font-size: 12px;
          line-height: 17px;
        }

        .value {
          font-size: 16px;
          line-height: 22px;
        }
      }

      .ticket-wrapper {
        padding: 15px 10px;

        .ticket-item {
          margin-top: 5px;

          .button {
            display: block;
          }
        }

        .ticket {
          font-size: 16px;
          line-height: 20px;
          padding: 5px;
          box-sizing: border-box;
        }

        .method {
          font-size: 14px;
          line-height: 19px;

          .button.pc {
            display: none;
          }
        }

        .img-title {
          font-size: 14px;
          line-height: 19px;
          margin-top: 20px;
        }

        .img {
          margin-top: 5px;
        }

        .btns {
          margin-top: 10px;
        }
      }

      .combination-wrapper {
        padding: 10px;

        .item {
          .title {
            font-size: 12px;
            line-height: 17px;

            &.bank-title {
              text-align: center;
              margin-bottom: 10px;
            }
          }

          .content {
            font-size: 12px;
            line-height: 17px;
            margin-top: 5px;
          }

          ::v-deep(.bank-desc) {
            font-size: 12px;
            line-height: 18px;
            margin-bottom: 15px;
          }

          ::v-deep(.bank-info-item) {
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }
  }
}
</style>
